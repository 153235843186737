import { useTranslation } from "react-i18next";
import useNavigation from "hooks/useNavigation";
import Button from "components/atomics/buttons/Button";
import { theme } from "@ribondao/shared/styles";
import LeftImage from "assets/images/bottom-left-shape.svg";
import RightImage from "pages/users/AppDownloadPage/assets/right-image.svg";
import { useTicketsContext } from "contexts/ticketsContext";
import IOSDownloadButton from "components/moleculars/buttons/IOSDownload";
import AndroidDownloadButton from "components/moleculars/buttons/AndroidDownload";
import { logEvent } from "lib/events";
import { useEffect } from "react";
import TicketIcon from "./assets/ticket.svg";
import InfinityIcon from "./assets/infinity.svg";
import Wallet from "./assets/wallet.svg";
import * as S from "./styles";

function FullWalletPage(): JSX.Element {
  const { t } = useTranslation("translation", {
    keyPrefix: "tickets.fullWalletPage",
  });
  const { navigateTo } = useNavigation();
  const { ticketsCounter } = useTicketsContext();
  const failedKey = "fullWallet";

  const handleNavigation = () => {
    if (ticketsCounter < 10)
      navigateTo({
        pathname: "/causes",
        state: {
          [failedKey]: true,
        },
      });
    else {
      navigateTo("/causes");
    }
  };

  useEffect(() => {
    logEvent("fullWalletNotice_view");
  }, []);

  return (
    <>
      <S.LeftImage src={LeftImage} />
      <S.RightImage src={RightImage} />
      <S.Container>
        <S.ImageContainer>
          <S.Image src={Wallet} />
        </S.ImageContainer>
        <S.ContentContainer>
          <S.TextContainer>
            <S.Title>{t("title")}</S.Title>

            <S.Subtitle>{t("subtitle")}</S.Subtitle>
          </S.TextContainer>

          <S.CardsContainer>
            <S.Card>
              <S.CardTopText>{t("walletLimit")}</S.CardTopText>
              <S.TicketsContainer>
                <S.TicketsQuantity>10</S.TicketsQuantity>
                <S.Icon src={TicketIcon} />
              </S.TicketsContainer>
              <S.CardBottomText>{t("withoutRibonClub")}</S.CardBottomText>
            </S.Card>
            <S.Card>
              <S.CardTopText>{t("walletLimit")}</S.CardTopText>
              <S.TicketsContainer>
                <S.Icon src={InfinityIcon} />
                <S.Icon src={TicketIcon} />
              </S.TicketsContainer>

              <S.CardBottomText>{t("withRibonClub")}</S.CardBottomText>
            </S.Card>
          </S.CardsContainer>
        </S.ContentContainer>
        <S.ButtonsContainer>
          <S.DownloadContainer>
            <AndroidDownloadButton />
            <IOSDownloadButton />
          </S.DownloadContainer>
          <Button
            text={t("skip")}
            onClick={handleNavigation}
            backgroundColor={theme.colors.neutral10}
            borderColor={theme.colors.brand.primary[600]}
            textColor={theme.colors.brand.primary[600]}
          />
        </S.ButtonsContainer>
      </S.Container>
    </>
  );
}

export default FullWalletPage;

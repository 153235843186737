import { useTranslation } from "react-i18next";
import Card from "components/moleculars/cards/Card";
import Data from "components/moleculars/Data";
import Title from "components/moleculars/Title";
import { theme } from "@ribondao/shared/styles";
import parse from "html-react-parser";
import AreaChart from "components/moleculars/charts/AreaChart";
import * as S from "./styles";

function StaffEnganementSection(): JSX.Element {
  const { t } = useTranslation("translation", {
    keyPrefix: "blackbaud.addin.staffEngagementSection",
  });

  const stats = {
    colaborators: "8,313",
    monthlyDonations: "8",
  };

  const titleAttributes = {
    title: t("title"),
    icon: {
      name: "featured_seasonal_and_gifts",
      color: theme.colors.brand.secondary[600],
    },
    secondaryColor: theme.colors.brand.secondary[50],
  };

  const colaboratorsAttributes = {
    label: t("colaborators"),
    data: stats.colaborators.toString(),
    color: theme.colors.brand.secondary[800],
  };

  const monthlyDonationsAttributes = {
    label: parse(t("monthlyDonations")),
    data: stats.monthlyDonations.toString(),
    color: theme.colors.brand.secondary[800],
  };

  const donors = [
    {
      name: "Erica Morgan",
      amount: "321 tickets donated",
    },
    {
      name: "Thomas Smith",
      amount: "284 tickets donated",
    },
    {
      name: "Patricia Harris",
      amount: "241 tickets donated",
    },
  ];

  type DailyStars = {
    month: string;
    donations: number;
  };

  type Series = {
    label: string;
    data: DailyStars[];
  };

  const dataChart: Series[] = [
    {
      label: "Colaborators",
      data: [
        {
          month: "Jan",
          donations: 6500,
        },
        {
          month: "Feb",
          donations: 7000,
        },
        {
          month: "Mar",
          donations: 6500,
        },
        {
          month: "Apr",
          donations: 7000,
        },
        {
          month: "May",
          donations: 6500,
        },
        {
          month: "Jun",
          donations: 6800,
        },
        {
          month: "Jul",
          donations: 6156,
        },
        {
          month: "Aug",
          donations: 6500,
        },
        {
          month: "Sep",
          donations: 7300,
        },
        {
          month: "Oct",
          donations: 7600,
        },
        {
          month: "Nov",
          donations: 7500,
        },
        {
          month: "Dec",
          donations: 7900,
        },
      ],
    },
  ];

  return (
    <Card
      border
      borderRadius={8}
      borderColor={theme.colors.brand.secondary[50]}
      backgroundColor="#FFFCFA"
    >
      <S.Container>
        <Title {...titleAttributes} />
        <S.DataContainer>
          <Data {...colaboratorsAttributes} />
          <Data {...monthlyDonationsAttributes} />
        </S.DataContainer>
      </S.Container>
      <AreaChart data={dataChart} />
      <S.Divider />
      <S.Subtitle>{t("subtitle")}</S.Subtitle>
      <S.DonorsContainer>
        {donors.map((donor) => (
          <S.DonorCard key={donor.name}>
            <S.DonorName>{donor.name}</S.DonorName>
            <S.DonorAmount>{donor.amount}</S.DonorAmount>
          </S.DonorCard>
        ))}
        <S.LastDonorCard>{t("seeMore")}</S.LastDonorCard>
      </S.DonorsContainer>
    </Card>
  );
}

export default StaffEnganementSection;

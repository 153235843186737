/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum TaxIdPTBR {
  ALEMANHA = "DE999999999",
  AUSTRIA = "ATU99999999",
  ARGENTINA = "99-99999999-9",
  BÉLGICA = "BE99999999999",
  BRASIL = "999.999.999-99",
  BULGÁRIA = "BG9999999999",
  ESTADOSUNIDOSDAAMÉRICA = "999-99-9999",
  CHILE = "99.999.999-9",
  CHIPRE = "CY99999999*",
  COREIADOSUL = "999-99-99999",
  CROÁCIA = "HR99999999999",
  DINAMARCA = "DK99999999",
  ESLOVÁQUIA = "SK9999999999",
  ESLOVÊNIA = "SI99999999",
  ESPANHA = "ES99999999",
  ESTÔNIA = "EE999999999",
  FINLÂNDIA = "FI99999999",
  FRANÇA = "FR99999999999",
  GRÉCIA = "EL999999999",
  HOLANDA = "NL999999999B99",
  HUNGRIA = "HU999999999999",
  INDONÉSIA = "99.999.999.9-999.999",
  IRLANDA = "IE9999999**",
  ITÁLIA = "IT99999999999",
  LETÔNIA = "LV99999999999",
  LIECHTENSTEIN = "CHE9999999999",
  LITUÂNIA = "LT999999999999",
  LUXEMBURGO = "LU99999999",
  MALTA = "MT99999999",
  NORUEGA = "999999999MVA",
  PAISESBAIXOS = "NL999999999B99",
  POLÔNIA = "PL9999999999",
  PORTUGAL = "PT999999999",
  REPÚBLICACHECA = "CZ999999999",
  ROMÊNIA = "RO9999999999",
  SUÉCIA = "SE999999999999",
}

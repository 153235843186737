import styled from "styled-components";

export const container = styled.div`
  width: 360px;
  height: 360px;
  position: absolute;
`;
export const lottieContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

import { useEffect } from "react";
import { useLanguage } from "hooks/useLanguage";
import Spinner from "components/atomics/Spinner";

function SurveyPage(): JSX.Element {
  const { currentLang } = useLanguage();

  const formURLs = {
    "pt-BR": "https://forms.gle/kPEgCDSZXpMVxWCYA",
    en: "https://forms.gle/ARYKViNGJBchSrQg6",
  };

  const currentUrl = currentLang === "pt-BR" ? formURLs["pt-BR"] : formURLs.en;

  useEffect(() => {
    window.location.replace(currentUrl);
  }, [currentUrl]);

  return (
    <div data-testid="survey-page">
      <Spinner />
    </div>
  );
}

export default SurveyPage;

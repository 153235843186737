import { theme } from "@ribondao/shared/styles";
import Button from "components/atomics/buttons/Button";
import { useTranslation } from "react-i18next";
import RibonLogo from "../assets/ribonLogo.svg";
import * as S from "./styles";

function HeaderSection(): JSX.Element {
  const { t } = useTranslation("translation", {
    keyPrefix: "blackbaud.addin.headerSection",
  });

  return (
    <S.Container>
      <S.Logo src={RibonLogo} />
      <S.Title>{t("title")}</S.Title>
      <S.Description>{t("description")}</S.Description>
      <S.ButtonContainer>
        <Button
          text={t("button")}
          textColor="white"
          backgroundColor={theme.colors.brand.primary[600]}
          borderColor={theme.colors.brand.primary[600]}
          onClick={() =>
            window.open("https://projetos.ribon.io/blackbaudribon", "_blank")
          }
        />
      </S.ButtonContainer>
    </S.Container>
  );
}

export default HeaderSection;

import { useTranslation } from "react-i18next";
import { logEvent } from "lib/events";
import Logo from "../../../../assets/icons/ribon-full.svg";
import Item from "../Item";

function Faq(): JSX.Element {
  const { t } = useTranslation("translation", {
    keyPrefix: "layouts.layoutHeader.settingsMenu.faq",
  });

  const handleClick = () => {
    logEvent("faq_click", {
      from: "config_page",
    });

    window.open(t("link"), "_blank");
  };

  return (
    <Item
      customIcon={Logo}
      text={t("text")}
      onClickHandler={() => handleClick()}
    />
  );
}

export default Faq;

import styled from "styled-components";
import {
  defaultBodyMdSemibold,
  defaultBodySmSemibold,
  defaultBodyXsMedium,
} from "styles/typography/default";

export const ProgressBarContainer = styled.div`
  display: block;
`;

export const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(16)};
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(112)};
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktopExtraLarge}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(24)};
    align-items: flex-start;
  }
`;

export const TitleContainer = styled.div`
  flex-shrink: 0;
`;

export const DataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(40)};
  align-items: center;
  justify-content: flex-start;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktopExtraLarge}) {
    gap: ${({ theme }) => theme.spacing(32)};
  }
`;

export const Divider = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(16)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[100]};
`;

export const Subtitle = styled.h2`
  ${defaultBodyMdSemibold}

  margin-bottom: ${({ theme }) => theme.spacing(12)};
  color: ${({ theme }) => theme.colors.neutral[700]};
`;

export const DonorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(16)};
  align-self: center;
  justify-content: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.pad}) {
    flex-direction: column;
  }
`;

export const DonorCard = styled.div`
  width: 142px;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(16)};
  border-radius: ${({ theme }) => theme.spacing(16)};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.brand.tertiary[50]};

  @media (max-width: ${({ theme }) => theme.breakpoints.pad}) {
    width: 100%;
  }
`;

export const DonorName = styled.p`
  ${defaultBodySmSemibold}

  color: ${({ theme }) => theme.colors.neutral[700]};
`;

export const DonorAmount = styled.p`
  ${defaultBodyXsMedium}

  color: ${({ theme }) => theme.colors.brand.tertiary[900]};
`;

export const LastDonorCard = styled.div`
  ${defaultBodyMdSemibold}

  width: 142px;
  height: auto;
  padding: ${({ theme }) => theme.spacing(20)};
  border-radius: ${({ theme }) => theme.spacing(16)};
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.brand.tertiary[50]};
  color: ${({ theme }) => theme.colors.brand.tertiary[900]};

  @media (max-width: ${({ theme }) => theme.breakpoints.pad}) {
    width: 100%;
  }
`;

import { theme } from "@ribondao/shared/styles";
import React from "react";
import { Chart, AxisOptions } from "react-charts";
import * as S from "./styles";

export type Props = {
  data: any;
  color?: any;
};

function AreaChart({
  data,
  color = theme.colors.brand.secondary[600],
}: Props): JSX.Element {
  const primaryAxis = React.useMemo(
    (): AxisOptions<any> => ({
      getValue: (datum) => datum.month,
      showGrid: false,
    }),
    [],
  );

  const secondaryAxes = React.useMemo(
    (): AxisOptions<any>[] => [
      {
        getValue: (datum) => datum.donations,
        elementType: "line",
        min: 0,
        max: 10000,
        scaleType: "linear",
        tickCount: 6,
        formatters: {
          scale: (value) =>
            [0, 2000, 4000, 6000, 8000, 10000].includes(value)
              ? value?.toString()
              : "",
          tooltip: (value) => value?.toString(),
        },
      },
    ],
    [],
  );

  return (
    <S.Container>
      <Chart
        options={{
          defaultColors: [color],
          data,
          primaryAxis,
          secondaryAxes,
          initialHeight: 0,
        }}
      />
    </S.Container>
  );
}
export default AreaChart;

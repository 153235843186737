import { IOS_APP_LINK } from "utils/constants";
import { useLanguage } from "hooks/useLanguage";
import { logEvent } from "lib/events";
import AppleBadge from "./assets/apple-badge-sm.svg";
import * as S from "./styles";

function IOSDownloadButton(): JSX.Element {
  const { currentLang } = useLanguage();

  const utmParamsFor = (campaign: string) => ({
    utmSource: currentLang === "pt-BR" ? "ribonweb_pt" : "ribonweb_en",
    utmMedium: "download_cta_screen",
    utmCampaign: campaign as string,
  });

  function handleIosLink() {
    logEvent("appStoreBtn_click", { ...utmParamsFor("desktop_ios") });
    logEvent("downloadCTA_click", {
      from: "appStoreBtn",
      ...utmParamsFor("desktop_ios"),
    });
    window.open(IOS_APP_LINK);
  }

  return (
    <S.Link
      onClick={() => handleIosLink()}
      target="_blank"
      rel="noopener noreferrer"
    >
      <S.ImageBadge src={AppleBadge} />
    </S.Link>
  );
}

export default IOSDownloadButton;

import styled from "styled-components";
import {
  defaultBodyMdMedium,
  defaultBodyXsRegular,
  defaultBodyXsSemibold,
} from "styles/typography/default";
import {
  stylizedDisplaySm,
  stylizedDisplayXs,
} from "styles/typography/stylized";

export const LeftImage = styled.img`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.pad}) {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
  }
`;
export const RightImage = styled.img`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.pad}) {
    position: absolute;
    right: 0;
    display: block;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  width: 328px;
  height: ${window.innerHeight}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  grid-gap: 0;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  margin-top: 24px;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 120px;
  align-items: center;
`;

export const Title = styled.span`
  ${stylizedDisplaySm}

  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[800]};
`;

export const TextContainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(12)};
  align-items: center;
  text-align: center;
`;

export const Subtitle = styled.span`
  ${defaultBodyMdMedium}

  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[600]};
`;

export const Link = styled.a`
  cursor: pointer;
`;

export const ImageBadge = styled.img`
  padding-left: ${({ theme }) => theme.spacing(40)};
`;

export const Icon = styled.img``;

export const CardsContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(8)};
  justify-content: center;
`;

export const Card = styled.div`
  width: 50%;
  padding: ${({ theme }) => theme.spacing(12)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(8)};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.brand.primary[25]};
`;

export const CardTopText = styled.span`
  ${defaultBodyXsRegular}

  color: ${({ theme }) => theme.colors.neutral[500]};
`;

export const CardBottomText = styled.span`
  ${defaultBodyXsSemibold}

  color: ${({ theme }) => theme.colors.neutral[700]};
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DownloadContainer = styled.div`
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  gap: ${({ theme }) => theme.spacing(8)};
`;

export const TicketsContainer = styled.div`
  height: 32px;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(4)};
  align-items: center;
`;

export const TicketsQuantity = styled.span`
  ${stylizedDisplayXs}

  color: ${({ theme }) => theme.colors.brand.primary[800]};
`;

import { ANDROID_APP_LINK } from "utils/constants";

import { useLanguage } from "hooks/useLanguage";
import { logEvent } from "lib/events";
import GoogleBadge from "./assets/google-badge-sm.svg";
import * as S from "./styles";

function AndroidDownloadButton(): JSX.Element {
  const { currentLang } = useLanguage();

  const utmParamsFor = (campaign: string) => ({
    utmSource: currentLang === "pt-BR" ? "ribonweb_pt" : "ribonweb_en",
    utmMedium: "download_cta_screen",
    utmCampaign: campaign as string,
  });

  function handleAndroidLink() {
    logEvent("gPlayBtn_click", { ...utmParamsFor("desktop_android") });
    logEvent("downloadCTA_click", {
      from: "gPlayBtn",
      ...utmParamsFor("desktop_android"),
    });
    window.open(ANDROID_APP_LINK);
  }

  return (
    <S.Link onClick={() => handleAndroidLink()} rel="noopener noreferrer">
      <S.ImageBadge src={GoogleBadge} />
    </S.Link>
  );
}

export default AndroidDownloadButton;

import styled from "styled-components";

export const Container = styled.div`
  padding: 0px 164px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 47px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.pad}) {
    padding: 16px;
  }
`;

export const TopImage = styled.img`
  width: 565px;
  position: absolute;
  top: 0;
  right: 0;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.pad}) {
    width: 180px;
    right: -60px;
  }
`;

import { useTranslation } from "react-i18next";
import Card from "components/moleculars/cards/Card";
import Data from "components/moleculars/Data";
import Title from "components/moleculars/Title";
import { theme } from "@ribondao/shared/styles";
import * as S from "./styles";

function FundraisingSection(): JSX.Element {
  const { t } = useTranslation("translation", {
    keyPrefix: "blackbaud.addin.fundraisingSection",
  });

  const stats = {
    totalRaised: "$170,560",
    toBeReceived: "$120,334",
  };

  const titleAttributes = {
    title: t("title"),
    icon: {
      name: "featured_seasonal_and_gifts",
      color: theme.colors.brand.tertiary[600],
    },
    secondaryColor: theme.colors.brand.tertiary[50],
  };

  const totalRaisedAttributes = {
    label: t("totalRaised"),
    data: stats.totalRaised.toString(),
    color: theme.colors.brand.tertiary[600],
  };

  const toBeReceivedAttributes = {
    label: t("toBeReceived"),
    data: stats.toBeReceived.toString(),
    color: theme.colors.brand.tertiary[600],
  };

  const donors = [
    {
      name: "Amanda Eisen...",
      amount: "$1,230.00",
    },
    {
      name: "Peter Applefield",
      amount: "$800.00",
    },
    {
      name: "Helen Goodman",
      amount: "$165.00",
    },
  ];

  return (
    <Card
      border
      borderRadius={8}
      borderColor={theme.colors.brand.tertiary[50]}
      backgroundColor={theme.colors.brand.tertiary[25]}
    >
      <S.Container>
        <Title {...titleAttributes} />
        <S.DataContainer>
          <Data {...totalRaisedAttributes} />
          <Data {...toBeReceivedAttributes} />
        </S.DataContainer>
      </S.Container>
      <S.Divider />
      <S.Subtitle>{t("subtitle")}</S.Subtitle>
      <S.DonorsContainer>
        {donors.map((donor) => (
          <S.DonorCard key={donor.name}>
            <S.DonorName>{donor.name}</S.DonorName>
            <S.DonorAmount>{donor.amount}</S.DonorAmount>
          </S.DonorCard>
        ))}
        <S.LastDonorCard>{t("seeMore")}</S.LastDonorCard>
      </S.DonorsContainer>
    </Card>
  );
}

export default FundraisingSection;

import { AddinClient } from "@blackbaud/sky-addin-client";
import BudgetSection from "./BudgetSection";
import HeaderSection from "./HeaderSection";
import topImage from "./assets/topImage.png";
import * as S from "./styles";
import FundraisingSection from "./FundraisingSection";
import StaffEnganementSection from "./StaffEngagementSection";

function BlackBaudAddInPage() {
  // eslint-disable-next-line no-new
  new AddinClient({
    callbacks: {
      init: (args) => {
        args.ready({
          showUI: true,
          title: "Ribon Dashboard",
        });
      },
    },
  });
  return (
    <S.Container>
      <S.TopImage src={topImage} />
      <HeaderSection />
      <BudgetSection />
      <FundraisingSection />
      <StaffEnganementSection />
    </S.Container>
  );
}

export default BlackBaudAddInPage;

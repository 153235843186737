import styled from "styled-components";

export const ProgressBarContainer = styled.div`
  display: block;
`;

export const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(24)};
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(112)};
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktopExtraLarge}) {
    margin-bottom: ${({ theme }) => theme.spacing(20)};
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(24)};
    align-items: flex-start;
  }
`;

export const TitleContainer = styled.div`
  flex-shrink: 0;
`;

export const DataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(40)};
  align-items: center;
  justify-content: flex-start;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktopExtraLarge}) {
    gap: ${({ theme }) => theme.spacing(32)};
  }

`;

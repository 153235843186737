import { useTranslation } from "react-i18next";
import Card from "components/moleculars/cards/Card";
import Data from "components/moleculars/Data";
import Title from "components/moleculars/Title";
import { theme } from "@ribondao/shared/styles";
import ProgressBar from "../ProgressBar";
import * as S from "./styles";

function BudgetSection(): JSX.Element {
  const { t } = useTranslation("translation", {
    keyPrefix: "blackbaud.addin.budgetSection",
  });

  const stats = {
    usagePercentage: 80,
    totalFund: "$600,000",
    allocatedByStaff: "$480,112",
    remainingFund: "$110,888",
  };

  const titleAttributes = {
    title: t("title"),
    icon: {
      name: "account_balance_wallet",
      color: theme.colors.brand.primary[600],
    },
    secondaryColor: theme.colors.brand.primary[50],
  };

  const progressBarAttributes = {
    label: `${stats.usagePercentage}%`,
    value: stats.usagePercentage,
    max: 100,
    min: 0,
  };

  const totalFundAttributes = {
    label: t("totalFund"),
    data: stats.totalFund.toString(),
    color: theme.colors.brand.primary[600],
  };

  const allocatedByStaffAttributes = {
    label: t("allocatedByStaff"),
    data: stats.allocatedByStaff.toString(),
    color: theme.colors.brand.primary[600],
  };

  const remainingFundAttributes = {
    label: t("remainingFund"),
    data: stats.remainingFund.toString(),
    color: theme.colors.brand.primary[600],
  };

  return (
    <Card
      border
      borderRadius={8}
      borderColor={theme.colors.brand.primary[50]}
      backgroundColor={theme.colors.brand.primary[25]}
    >
      <S.Container>
        <Title {...titleAttributes} />
        <S.DataContainer>
          <Data {...totalFundAttributes} />
          <Data {...allocatedByStaffAttributes} />
          <Data {...remainingFundAttributes} />
        </S.DataContainer>
      </S.Container>
      <S.ProgressBarContainer>
        <ProgressBar {...progressBarAttributes} />
      </S.ProgressBarContainer>
    </Card>
  );
}

export default BudgetSection;

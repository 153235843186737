/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum TaxIdUS {
  GERMANY = "DE999999999",
  AUSTRIA = "ATU99999999",
  ARGENTINA = "99-99999999-9",
  BELGIUM = "BE99999999999",
  BRAZIL = "999.999.999-99",
  BULGARIA = "BG9999999999",
  UNITEDSTATESOFAMERICA = "999-99-9999",
  CHILE = "99.999.999-9",
  CYPRUS = "CY99999999*",
  SOUTHKOREAN = "999-99-99999",
  CROATIA = "HR99999999999",
  DENMARK = "DK99999999",
  SLOVAKIA = "SK9999999999",
  SLOVENIA = "SI99999999",
  ESPANHA = "ES99999999",
  ESTONIA = "EE999999999",
  FINLAND = "FI99999999",
  FRANCE = "FR99999999999",
  GREECE = "EL999999999",
  HOLLAND = "NL999999999B99",
  HUNGARY = "HU999999999999",
  INDONESIA = "99.999.999.9-999.999",
  IRELAND = "IE9999999**",
  ITALY = "IT99999999999",
  LATVIA = "LV99999999999",
  LIECHTENSTEIN = "CHE9999999999",
  LITHUANIA = "LT999999999999",
  LUXEMBOURG = "LU99999999",
  MALTA = "MT99999999",
  NETHERLANDS = "NL999999999B99",
  NORWAY = "999999999MVA",
  UNITEDKINGDON = "GB999999999",
  SPAIN = "ES99999999",
  POLAND = "PL9999999999",
  PORTUGAL = "PT999999999",
  CZECHREPUBLIC = "CZ999999999",
  ROMANIA = "RO9999999999",
  SWEDEN = "SE999999999999",
}

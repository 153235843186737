import styled from "styled-components";
import { defaultBodyMdRegular } from "styles/typography/default";
import { stylizedDisplayLg } from "styles/typography/stylized";

export const Container = styled.div`
  max-width: 600px;
  padding: ${({ theme }) => theme.spacing(64, 0)};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(16)};
`;

export const Logo = styled.img`
  width: 118px;
  margin-bottom: ${({ theme }) => theme.spacing(24)};
`;

export const Title = styled.h1`
  ${stylizedDisplayLg}

  color: ${({ theme }) => theme.colors.neutral[800]};
`;

export const Description = styled.p`
  ${defaultBodyMdRegular}

  color: ${({ theme }) => theme.colors.neutral[700]};
`;

export const ButtonContainer = styled.div`
  * {
    width: auto;
  }
`;

import styled, { css } from "styled-components";

export const Container = styled.div<{
  backgroundColor: string;
  border: boolean;
  borderRadius?: number;
  borderColor?: string;
}>`
  margin-bottom: ${({ theme }) => theme.spacing(40)};
  padding: ${({ theme }) => theme.spacing(32)};
  border-radius: ${(props) => props.borderRadius || 8}px;
  background: ${(props) =>
    props.backgroundColor || props.theme.colors.neutral10};
  ${(props) =>
      props.border &&
      css`
        border: 1px solid;
        border-color: ${props.borderColor};
      `}

  @media (min-width: ${({ theme }) => theme.breakpoints.pad}) {
    margin-bottom: ${({ theme }) => theme.spacing(16)};
    ${(props) =>
      props.border &&
      css`
        border: 1px solid;
        border-color: ${props.borderColor} ;
      `}
  }
`;

import { PLATFORM } from "utils/constants";
import { useCurrentUser } from "contexts/currentUserContext";
import { useTickets } from "@ribondao/shared/hooks";
import { useIntegrationContext } from "contexts/integrationContext";
import {
  RECEIVED_TICKET_AT_KEY,
  RECEIVED_TICKET_FROM_INTEGRATION,
} from "lib/localStorage/constants";
import { today } from "lib/dateTodayFormatter";
import { getLocalStorageItem } from "lib/localStorage";
import { logError } from "services/crashReport";
import { useIntegrationId } from "hooks/useIntegrationId";
import useNavigation from "hooks/useNavigation";

type HandleCollectProps = {
  onSuccess?: () => void;
  onError?: (error: any) => void;
};

export function useCollectTickets() {
  const { currentUser } = useCurrentUser();

  const {
    canCollectByExternalIds,
    canCollectByIntegration,
    collectByExternalIds,
    collectByIntegration,
  } = useTickets();

  const integrationId = useIntegrationId();
  const { externalIds, setExternalIds } = useIntegrationContext();
  const { navigateTo } = useNavigation();

  function hasReceivedTicketToday() {
    const receivedTicketAtKey = getLocalStorageItem(RECEIVED_TICKET_AT_KEY);
    const receivedTickedFromIntegrationKey = getLocalStorageItem(
      RECEIVED_TICKET_FROM_INTEGRATION,
    );

    if (
      receivedTicketAtKey &&
      receivedTickedFromIntegrationKey === integrationId?.toLocaleString()
    ) {
      const dateUserSawToast = new Date(parseInt(receivedTicketAtKey, 10));
      return dateUserSawToast.toLocaleDateString() === today();
    }
    return false;
  }

  const handleFullWallet = (error: any) => {
    if (error.response.data.formatted_message.includes("Full wallet")) {
      navigateTo("/full-wallet");
    }
  };

  async function handleCanCollect() {
    if (externalIds && externalIds.length > 0) {
      try {
        const { canCollect, quantity } = await canCollectByExternalIds(
          externalIds,
          true,
        );
        return { canCollect, quantity };
      } catch (e) {
        return handleFullWallet(e);
      }
    } else if (integrationId) {
      try {
        const { canCollect } = await canCollectByIntegration(
          integrationId,
          currentUser?.email ?? "",
          true,
        );
        return { canCollect, quantity: 1 };
      } catch (e) {
        return handleFullWallet(e);
      }
    } else {
      return { canCollect: false, quantity: 0 };
    }
  }

  async function handleCollect({ onError, onSuccess }: HandleCollectProps) {
    if (externalIds && externalIds.length > 0 && integrationId) {
      try {
        await collectByExternalIds(
          externalIds,
          integrationId ?? "",
          PLATFORM,
          currentUser?.email ?? "",
          true,
        );
        if (onSuccess) {
          setExternalIds(undefined);
          onSuccess();
        }
      } catch (e: any) {
        logError(e);
        handleFullWallet(e);
        if (onError) onError(e);
      }
    } else if (integrationId) {
      try {
        await collectByIntegration(
          integrationId,
          PLATFORM,
          currentUser?.email ?? "",
          true,
        );
        if (onSuccess) onSuccess();
      } catch (e: any) {
        logError(e);
        handleFullWallet(e);
        if (onError) onError(e);
      }
    }
  }

  return {
    handleCanCollect,
    handleCollect,
    hasReceivedTicketToday,
  };
}

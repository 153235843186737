const events: any = {
  pages: {
    "/causes": "P26",
    "/post-donation": "P8",
    "/intro": "P10",
    "/tickets": "P11",
    "/return-to-integration": "P11",
    "/app-download": "P17",
  },
};

export default events;
